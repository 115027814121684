@use "src/variables";

.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 8px 12px;
  font-weight: 500;
  box-sizing: border-box;
  font-size: 13px;
  @media (max-width: variables.$breakpoint-lg) {
    padding: 6px 8px;
  }
  &:hover {
    border: 1px solid variables.$primary-color;
  }
  &-default {
    background-color: white;
    box-shadow: 2px 2px rgba($color: variables.$primary-color, $alpha: 0.2);
    &:hover {
      background-color: variables.$primary-color;
      color: white;
      svg path {
        fill: white;
      }
    }
  }
  &-disabled {
    background-color: #dadada;
    opacity: 0.3;
    border-color: transparent;
  }
  &-border {
    border: 1px solid variables.$secondary-color;
    box-shadow: none;
  }
  &-secondary {
    padding: 4px 8px;
    background-color: variables.$primary-color;
    color: white;
    font-weight: 400;
    font-size: 15px;
    &:hover {
      background-color: white;
      color: black;
    }
  }
  &-admin {
    color: white;
    background-color: variables.$admin-color;
    border-radius: 5px;
    text-transform: capitalize;
    &:hover {
      opacity: 0.8;
    }
    svg {
      width: 13px !important;
      height: 13px !important;
      path {
        fill: transparent !important;
        stroke: white;
      }
    }
  }
  &-icon {
    margin-left: 6px;
    svg {
      width: 12px;
      height: 10px;
      path {
        fill: black;
      }
    }
    &-left {
      margin-right: 8px;
      margin-left: 0;
      svg {
        vertical-align: top;
        width: 18px;
        height: 18px;
      }
    }
  }
  &-top {
    flex-direction: column-reverse;
    text-align: center;
    justify-content: center;
    padding: 16px 12px;
    @media (max-width: variables.$breakpoint-lg) {
      padding: 8px;
    }
    > div {
      margin-left: 0;
    }
    svg {
      width: 25px;
      height: 25px;
      @media (max-width: variables.$breakpoint-lg) {
        width: 22px;
        height: 22px;
      }
    }
  }
}
