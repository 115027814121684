@use "src/variables";

.recordings {
  &-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
  }
  &-video {
    width: 65%;
    > div {
      height: 100%;
      video {
        border-radius: 15px;
        width: 100%;
      }
    }
  }
  &-field-container {
    width: 34%;
  }
  &-audio {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    > div {
      width: 18%;
      height: 56px;
      margin-top: 16px;
      cursor: pointer;
    }
    &-container {
      width: 100%;
    }
  }
  &-bottom {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 240px;
    margin-top: 12px;
  }
  &-current {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow-x: hidden;
    width: 100%;

    &:not(:first-child) {
      margin-top: 12px;
    }
    > div {
      &:first-child {
        width: 65%;
      }
      &:last-child {
        width: 34%;
        height: 112px;
      }
    }
    &-list {
      width: calc(100% + 8px);
      > div:last-child {
        width: calc(34% - 12px);
        margin-right: 12px;
      }
    }
  }
}
