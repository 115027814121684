@use "src/variables";

.video-controls {
  padding-bottom: 4px;
  background-color: rgba($color: variables.$primary-color, $alpha: 1);
  border-radius: 10px;
  color: white;
  &-progress {
    width: calc(100% - 178px);
    margin: 0 8px;
    > div:first-child {
      position: relative;
      display: flex;
      align-items: center;
      height: 16px;
      > span {
        position: absolute;
        bottom: 2px;
        z-index: 10;
        opacity: 0.5;
        border-top: 12px solid transparent;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;

        &:hover {
          cursor: pointer;
          opacity: 1;
        }
      }
    }
  }
  &-top {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
    > small {
      margin-top: 12px;
      font-size: 12px;
      width: 80px;
      text-align: center;
    }
  }
  &-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    padding: 0 4px;
    > div {
      display: flex;
      align-items: center;
      > span {
        cursor: pointer;
        padding: 2px 6px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        b {
          font-size: 13px;
        }
        svg {
          width: 21px;
          height: 21px;
          stroke: white;
        }
        &:hover {
          background-color: rgba($color: #ffffff, $alpha: 0.1);
        }
      }
    }
  }
  &-volume {
    &-content {
      padding: 8px 12px;
      border-radius: 5px;
    }
    > div > div:first-child {
      padding: 2px 4px;
      border-radius: 5px;
      svg {
        width: 21px;
        height: 21px;
        stroke: white;
        path {
          fill: none;
        }
      }
      &:hover {
        background-color: rgba($color: #ffffff, $alpha: 0.1);
      }
    }
  }
  .big-icon {
    width: 24px;
    height: 24px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
