@use "src/variables";

.trend {
  > span {
    display: flex;
    align-items: center;
  }
  svg {
    width: 16px;
    height: 10px;
    margin-right: 4px;
  }
  &-POSITIVE {
    color: variables.$POSITIVE-color;
    svg {
      transform: rotate(180deg);
      path {
        fill: variables.$POSITIVE-color;
      }
    }
  }
  &-NEGATIVE {
    color: variables.$NEGATIVE-color;
    svg path {
      fill: variables.$NEGATIVE-color;
    }
  }
  &-NEUTRAL {
    color: variables.$primary-color;
  }
}
