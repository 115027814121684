@use "src/variables";

.feedback-widget {
  padding: 16px;
  > span {
    color: variables.$secondary-color;
  }
  &-percentage {
    margin-top: 16px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    overflow: hidden;
    > div {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(100% - 36px);
      min-height: 75px;
      color: white;
      transition: width 1s;
    }
  }
  &-simple {
    font-size: 18px;
    background-color: beige;
    &:first-child {
      background-color: variables.$POSITIVE-color;
      position: relative;
    }
    &:last-child {
      background-color: variables.$NEGATIVE-color;
    }
    > div {
      padding: 8px 4px;
      position: absolute;
      top: 25%;
      z-index: 2;
      &:first-child {
        left: 4px;
        width: 50px;
        text-align: center;
      }
      &:last-child {
        left: 56px;
      }
    }
    &:last-child > div {
      display: none;
    }
    &-none:last-child > div {
      display: block;
    }
  }
  &-complex {
    &:first-child {
      background-color: #2c2f51;
      > div:first-child {
        top: 3px;
        left: 5px;
      }
    }
    &:nth-child(2) {
      background-color: #486fd4;
    }
    &:nth-child(3) {
      background-color: #91aae8;
    }
    &:last-child {
      background-color: #d3d3d3;
    }
    > div {
      font-size: 11px;
      position: absolute;
      &:first-child {
        top: 3px;
        left: 2px;
      }
      &:last-child {
        font-size: 8px;
        width: 70px;
        height: 10px;
        bottom: 33px;
        right: -29px;
        transform: rotate(-90deg);
      }
    }
  }
}
