@use "src/variables";

.video-player {
  width: 100%;
  &-button {
    position: absolute;
    top: calc(50% - 30px);
    right: calc(50% - 35px);

    margin: auto;
    color: white;
    div {
      height: 60px;
      width: 60px;
    }
    span {
      background-color: variables.$primary-color;
      border: 2px solid white;
    }
    svg {
      width: 60px;
      height: 60px;
      cursor: pointer;
      fill: variables.$primary-color;
    }
  }
  &-file {
    min-height: 400px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    button {
      border: 1px solid #5085f0;
      padding: 12px 16px;
      font-size: 18px;
      color: #5085f0;
      background-color: white;
      border-radius: 10px;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
}
