.audio-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85%;
  max-height: 35px;
  > span {
    width: 8%;
    max-width: 8px;
    border-radius: 5px;
    background-color: white;
  }
  &-0,
  &-7 {
    height: 10px;
  }
  &-1,
  &-3 {
    height: 14px;
  }
  &-4 {
    height: 8px;
  }
  &-5 {
    height: 10px;
  }
  &-2 {
    height: 22px;
  }
  &-6 {
    height: 17px;
  }
}
