@use "src/variables";

.audio-sync {
  background-color: variables.$primary-color;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  box-sizing: border-box;
  &-input {
    width: calc(100% - 50px);
    padding: 8px 12px;
    border-right: 1px solid variables.$tertiary-color;
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:first-child {
        margin-bottom: 16px;
      }
      label {
        margin-right: 8px;
        min-width: 130px;
      }
    }
  }
  &-button {
    width: 80px;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    svg {
      width: 40px;
      cursor: pointer;
    }
  }
}
.custom-input {
  display: flex;
  align-items: center;
  gap: 2px;
  background-color: white;
  color: black;
  height: 30px;
  border-radius: 5px;
  padding: 0 4px;
  vertical-align: middle;
  > input {
    width: 25%;
    padding: 2px;
    border: 0;
    height: 100%;
    box-sizing: border-box;
    border-radius: 5px;
  }
  > b {
    margin-bottom: 6px;
  }
}
