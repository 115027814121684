/* Breakpoints */
$breakpoint-sm: calc(576px + 50px) !default;
$breakpoint-md: calc(768px + 50px) !default;
$breakpoint-lg: calc(992px + 280px) !default;
$breakpoint-xl: calc(1200px + 280px) !default;
$breakpoint-xxl: calc(1400px + 280px) !default;
/* Colors */
$primary-color: #333333;
$secondary-color: #9a9a9a;
$tertiary-color: #c4c4c4;
$admin-color: #0e2e86;
$POSITIVE-color: #21ce71;
$NEGATIVE-color: #f05056;
