@use "src/variables";

.menu-item {
  background-color: transparent;
  color: white;
  border-radius: 5px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  text-decoration: none;
  @media (max-width: variables.$breakpoint-lg) {
    padding: 8px;
  }
  > span {
    width: calc(100% - 45px);
    white-space: nowrap;
  }
  &-icon {
    width: 21px;
    height: 21px;
    display: flex;
    align-items: center;
    svg {
      width: 20px;
      height: 20px;
      vertical-align: middle;

      path {
        fill: white;
      }
    }
  }
}
.menu-item:hover,
.active {
  background-color: white;
  color: variables.$primary-color;
  > span {
    border-right: 1px solid variables.$primary-color;
  }
  svg path {
    fill: variables.$primary-color;
  }
}
