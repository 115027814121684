@use "src/variables";
::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: variables.$secondary-color; /* Customize the color of the thumb (scrollable area) */
  border-radius: 5px; /* Add rounded corners to the thumb */
}

.container {
  width: 92%;
  margin: 36px auto;
  @media (max-width: variables.$breakpoint-lg) {
    height: calc(100% - 48px);
    margin: 24px auto;
    font-size: 13px;
  }
}
.layout {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  &-sidebar {
    z-index: 100;
    width: fit-content;
    &-admin > div {
      background-color: variables.$admin-color;
    }
  }
  &-body {
    background-color: #f5f5f5;
    // width: calc(100% - 280px);
    width: calc(100% - 50px);
    @media (max-width: variables.$breakpoint-lg) {
      width: calc(100% - 45px);
    }
  }
  &-content {
    margin-top: 36px;
    border-radius: 10px;
    @media (max-width: variables.$breakpoint-lg) {
      margin-top: 28px;
    }
  }
}
.section {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > h1 {
      margin: 0;
      font-size: 28px;
      @media (max-width: variables.$breakpoint-lg) {
        font-size: 24px;
      }
    }
    &-btn {
      display: flex;
      align-items: center;
      > div:first-child {
        margin-right: 48px;
      }
    }
  }
  &-item {
    background-color: white;
    box-shadow: 3px 5px rgba($color: variables.$primary-color, $alpha: 0.2);
    border-radius: 10px;
  }
}
.widget-container {
  padding: 16px;
  background-color: white;
  box-shadow: 3px 5px rgba($color: variables.$primary-color, $alpha: 0.2);
  border-radius: 10px;
  box-sizing: border-box;
  @media (max-width: variables.$breakpoint-lg) {
    padding: 12px;
  }
  h3 {
    font-size: 2.1rem;
    margin: 0;
    @media (max-width: variables.$breakpoint-xl) {
      font-size: 1.8rem;
    }
  }
  h6 {
    color: variables.$secondary-color;
    margin: 0;
    font-size: 16px;
    display: flex;
    align-items: flex-start;
    white-space: pre-wrap;
    @media (max-width: variables.$breakpoint-lg) {
      font-size: 13px;
    }
  }
  small {
    color: variables.$tertiary-color;
    text-align: right;
  }
}
.widget-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  > div {
    font-size: 18px;
  }
}
.checkmark {
  svg {
    width: 20px;
    height: 20px;
    @media (max-width: variables.$breakpoint-lg) {
      width: 17px;
      height: 17px;
    }
  }
  &-rotate svg {
    transform: rotate(45deg);
  }
}
.score {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  @media (max-width: variables.$breakpoint-lg) {
    width: 17px;
    height: 17px;
  }
  &-POSITIVE {
    background-color: variables.$POSITIVE-color;
  }
  &-NEGATIVE {
    background-color: variables.$NEGATIVE-color;
  }
  &-NEUTRAL {
    background-color: #f0e050;
  }
}
.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: 2px solid variables.$tertiary-color;
  > span {
    padding: 0 8px 12px 8px;
    margin-bottom: -19px;
    border-bottom: 3px solid variables.$admin-color;
    font-size: 18px;
    @media (max-width: variables.$breakpoint-lg) {
      font-size: 14px;
    }
  }
}
input[type="checkbox"] {
  cursor: pointer;
  width: 15px;
  height: 15px;
  accent-color: variables.$primary-color;
  &:checked {
    content: "\002714";
    background-color: #000000;
  }
}
.rdrInputRanges {
  display: none !important;
}

.rdrDefinedRangesWrapper {
  width: 120px !important;
  padding: 8px;
  border-right: 1px solid variables.$tertiary-color !important;
  border-top-left-radius: 10px !important;

  .rdrStaticRangeSelected,
  .rdrStaticRangeLabel:hover {
    background-color: variables.$tertiary-color !important;
    color: white !important;
    cursor: pointer;
    border-radius: 5px;
  }
}
.rdrMonth {
  width: 250px !important;
}
.rdrStaticRangeLabel {
  padding: 8px !important;
}
.rdrCalendarWrapper,
.rdrDateDisplayWrapper {
  border-top-right-radius: 10px !important;
}
.rdrDateDisplayWrapper {
  background-color: white !important;
}
