@use "src/variables";

.tags {
  margin-top: 12px;
  > div {
    min-height: 45px;
  }
  &-list {
    overflow-y: auto;
    max-height: 250px;
    border-radius: 10px;
    padding: 12px 8px 12px 0;
    box-sizing: content-box;
    > div {
      width: 100%;
    }
  }
  &-content {
    border-top: 2px solid variables.$tertiary-color;
    padding-top: 8px;
    display: flex;
    align-items: center;
    &-left {
      flex: 1;
      margin-right: 8px;
      > h6 {
        margin-bottom: 8px;
        align-items: center;
      }
      > textarea {
        width: 95%;
        border-radius: 5px;
        border-width: 2px;
        padding: 6px 4px;
      }
    }
    &-right {
      > div:first-child {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        margin-bottom: 16px;
      }
      > div:not(:first-child) {
        width: 75px;
        margin-top: 8px;
      }
    }
  }
}
