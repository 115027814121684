@use "src/variables";

.create-team {
  &-form {
    display: block;
    > div {
      margin-bottom: 24px;
      @media (max-width: variables.$breakpoint-lg) {
        margin-bottom: 20px;
      }
      label {
        display: inline-block;
        margin-bottom: 8px;
        small {
          font-style: italic;
          opacity: 0.7;
        }
      }
      > input {
        padding: 0 8px;
        height: 40px;
        border-radius: 5px;
        border-color: #d5d5d5;
        width: 100%;
        box-sizing: border-box;
        @media (max-width: variables.$breakpoint-lg) {
          height: 35px;
        }
      }
    }
  }
  &-button {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
  }
}
