@use "src/variables";

.classifications {
  > span {
    display: flex;
    justify-content: center;
  }
  &-content {
    margin: 24px 0;
    border-radius: 5px;
    display: flex;
    height: 90px;
    overflow: hidden;
    @media (max-width: variables.$breakpoint-lg) {
      height: 75px;
    }
    > div {
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      color: white;
      &:first-child {
        background-color: #2c2f51;
      }
      &:nth-child(2) {
        background-color: #486fd4;
      }
      &:nth-child(3) {
        background-color: #91aae8;
      }
      &:last-child {
        background-color: #d3d3d3;
      }
      > span {
        margin-right: 4px;
        margin-bottom: 4px;
      }
    }
  }
  &-footer {
    display: flex;
    justify-content: space-between;
    > div {
      width: 24%;
      font-size: 80%;
      &:first-child > span {
        background-color: #2c2f51;
      }
      &:nth-child(2) > span {
        background-color: #486fd4;
      }
      &:nth-child(3) > span {
        background-color: #91aae8;
      }
      &:last-child > span {
        background-color: #d3d3d3;
      }

      > span {
        display: block;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        margin-bottom: 8px;
      }
    }
  }
}
