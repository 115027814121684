@use "src/variables";

.player-select {
  position: relative;
  &-dropdown {
    width: 100%;
    > div:first-child {
      height: 40px;
      @media (max-width: variables.$breakpoint-lg) {
        height: 35px;
      }
    }
  }
  &-content {
    display: none;
    position: absolute;
    right: 0;
    left: 0;
    background-color: white;
    border: 1px solid variables.$tertiary-color;
    border-radius: 5px;
    z-index: 100;
    white-space: nowrap;
    > input {
      box-sizing: border-box;
      width: 100%;
      padding: 8px;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      > li {
        cursor: pointer;
        padding: 4px 12px;
        display: flex;
        align-items: center;
        text-transform: capitalize;
        > input[type="checkbox"] {
          margin-right: 8px;
          cursor: pointer;
          width: 15px;
          height: 15px;
          accent-color: variables.$admin-color;
          &:checked {
            content: "\002714";
            background-color: variables.$admin-color !important;
          }
        }
        &:hover {
          background-color: rgba($color: variables.$admin-color, $alpha: 0.3);
        }
        &:not(:last-child) {
          border-bottom: 1px solid variables.$tertiary-color;
        }
      }
    }
    &-open {
      display: block;
    }
  }
  &-list {
    margin-top: 16px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @media (max-width: variables.$breakpoint-lg) {
      margin-top: 8px;
    }
    > div {
      margin-right: 24px;
      margin-bottom: 8px;
      @media (max-width: variables.$breakpoint-lg) {
        margin-right: 16px;
      }
    }
  }
}
