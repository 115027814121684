@use "src/variables";

.color-picker > div {
  > div > div {
    margin-left: 4px;
    padding: 2px 4px !important;
  }
  span {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid variables.$primary-color;
  }
}
