@use "src/variables";

.sentiment-widget {
  > div {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  &-content {
    margin: 12px 0;
  }
  &-footer {
    > span {
      display: flex;
      align-items: center;
      svg {
        width: 14px;
        height: 10px;
        margin-right: 4px;
      }
    }
  }
  &-graph {
    width: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: 65px;
    @media (max-width: variables.$breakpoint-lg) {
      width: 60%;
    }
    > div {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background-color: #96c0ff;
      transition: height 1s ease;
      &:nth-child(2n) {
        background-color: #0066ff;
      }
    }
  }
}
