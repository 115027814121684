@use "src/variables";

.tooltip {
  margin: 2px 4px 0 4px;
  background-color: variables.$secondary-color;
  border: 1px solid white;
  width: 15px;
  min-width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  z-index: auto;
  font-size: 13px;
  font-style: italic;
  @media (max-width: variables.$breakpoint-lg) {
    width: 13px;
    height: 13px;
  }
  > small {
    color: white;
    font-style: italic;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 12px;
    @media (max-width: variables.$breakpoint-lg) {
      font-size: 10px;
    }
  }
  > div {
    position: absolute;
    bottom: 20px;
    left: -40px;
    padding: 8px;
    border-radius: 5px;
    background-color: rgba($color: #000000, $alpha: 0.8);
    color: white;
    display: flex;
    flex-direction: column;
    z-index: 150;

    // h6 {
    //   margin-bottom: 8px;
    //   margin-top: 0;
    //   color: white;
    //   text-decoration: underline;
    //   &:not(:first-child) {
    //     margin-top: 12px;
    //   }
    // }
    p {
      margin-top: 0;
    }
    ul {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: fit-content;
      margin: 0;
      padding: 0 0 0 12px;
    }
  }
}
