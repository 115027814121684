@use "src/variables";

.sessions-table {
  overflow-x: auto;

  table {
    min-width: 1100px;
    width: 100%;
    border-collapse: collapse;
    thead {
      border-bottom: 1px solid variables.$tertiary-color;
      height: 60px;
    }
    tbody tr {
      height: 60px;
      border-bottom: 1px solid #f3f3f3;
      cursor: pointer;
      &:hover {
        background-color: rgba($color: #dadada, $alpha: 0.2);
      }
    }
    th,
    td {
      text-align: left;
      text-transform: capitalize;
      padding: 8px;
      &:first-child {
        padding-left: 16px;
        input[type="checkbox"] {
          width: 18px;
          height: 18px;
        }
      }
      &:nth-last-child(-n + 6) {
        max-width: 100px;
      }
      &:last-child {
        width: 15px;
        padding-right: 16px;
        svg {
          height: 18px;
        }
      }
    }
    thead tr {
      th > span {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-weight: normal;
        svg {
          width: 12px;
          height: 10px;
          margin-left: 8px;
        }
      }
    }
  }
  &-language svg {
    width: 22px;
    height: 18px;
  }

  &-video {
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 4px 8px;
    width: fit-content;
    border: 1px solid variables.$secondary-color;
    background-color: #f3f3f3;
    &:hover {
      background-color: #dadada;
    }
    svg {
      margin-right: 4px;
      margin-top: 2px;
      width: 14px;
      height: 14px;
    }
    &-upload {
      border-style: dashed;
    }
  }
}
