@use "src/variables";

.dropdown {
  position: relative;
  &-profile {
    display: flex;
    align-items: center;
    cursor: pointer;
    > span {
      margin-right: 8px;
      border-radius: 50%;
      background-color: black;
      color: white;
      font-size: 18px;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-admin {
    > div {
      min-height: 45px;
      height: 45px;
      @media (max-width: variables.$breakpoint-lg) {
        min-height: 40px;
        height: 40px;
      }
      svg path {
        fill: white;
      }
    }
  }
  &-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: white;
    border: 1px solid variables.$tertiary-color;
    border-radius: 5px;
    z-index: 100;
    white-space: nowrap;
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      > li {
        cursor: pointer;
        padding: 4px 12px;
        &:hover {
          background-color: rgba($color: variables.$tertiary-color, $alpha: 1);
        }
        &:not(:last-child) {
          border-bottom: 1px solid variables.$tertiary-color;
        }
      }
    }
    &-open {
      display: block;
    }
    &-profile {
      min-width: 175px;
      ul > li {
        cursor: default;
        padding: 8px 0;
        &:hover {
          background-color: transparent;
        }
      }
    }
    &-right {
      right: auto;
      left: 0;
    }
    &-top {
      background-color: red;
      top: -80px;
    }
  }
  .open svg {
    transform: rotate(180deg);
  }
}
