.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-left > div:first-child {
    min-width: 90px;
  }
  &-right,
  &-left {
    display: flex;
    align-items: center;
    > div:not(:last-child) {
      margin-right: 48px;
    }
  }
  &-profile {
    > li,
    &-coach-switch {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    &-coach {
      flex-direction: column;
      > input {
        margin-top: 8px;
        border-radius: 5px;
        padding: 2px 4px;
      }
    }
  }
}
