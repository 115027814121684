@use "src/variables";
.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  > div:last-child {
    display: flex;
    &-selected b {
      text-decoration: underline;
    }
    > div {
      cursor: pointer;
      &:first-child {
        margin-left: 8px;
      }
      &:not(:last-child)::after {
        content: "-";
        margin: 0 4px;
      }
      > b {
        font-size: 18px;
        @media (max-width: variables.$breakpoint-lg) {
          font-size: 16px;
        }
      }
    }
  }
}
