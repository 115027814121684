@use "src/variables";

.field-audio-overview {
  width: 100%;
  &-top {
    background-image: url("../../../../public/img/field.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 55%;
    min-height: 390px;
    border-radius: 10px 10px 0 0;
    position: relative;
    @media (max-width: variables.$breakpoint-lg) {
      min-height: 320px;
    }
    &-rotate {
      background-image: url("../../../../public/img/field-flipped.png");
      .field-audio-overview-players {
        transform: rotate(180deg);
        padding: 12px 4px 24px 4px;
        > div {
          transform: rotate(180deg);
          margin-bottom: 24px;
          @media (max-width: variables.$breakpoint-lg) {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
  &-players {
    padding: 24px 4px 12px 4px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    > div:not(:last-child) {
      margin-bottom: 20px;
      @media (max-width: variables.$breakpoint-lg) {
        margin-bottom: 12px;
      }
    }
  }
  &-coach {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    > span:last-child {
      margin-left: 8px;
    }
  }
  &-formation {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 10;
    display: flex;
    align-items: center;
    > span {
      margin-right: 8px;
      padding: 1px;
      background-color: black;
      cursor: pointer;
      border-radius: 50%;
      border: 1px solid black;
      display: flex;
      align-items: center;
      width: 14px;
      height: 14px;
      svg {
        margin: 0 0 1px -1px;
        path {
          fill: white;
          transform: scale(0.7);
        }
      }
    }
    > b {
      border-radius: 10px;
      padding: 2px 6px;
      font-size: 13px;
      background-color: rgba($color: #000000, $alpha: 0.3);
    }
  }
  &-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba($color: #dadada, $alpha: 0.5);
    padding: 8px 2px;
    border: 1px solid variables.$tertiary-color;
    svg {
      cursor: pointer;
    }
    .field-audio-overview-swaps {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      > div {
        width: 25%;
        margin-bottom: 4px;
        > div {
          border-color: variables.$primary-color;
          background-color: rgba($color: variables.$primary-color, $alpha: 0.6);
          width: 42px;
          height: 42px;
          @media (max-width: variables.$breakpoint-lg) {
            width: 36px;
            height: 36px;
          }
        }
        > span {
          background-color: rgba($color: variables.$primary-color, $alpha: 0.6);
        }
      }
    }
  }
}
