@use "src/variables";

.player {
  display: flex;
  flex-direction: column;
  justify-self: center;
  &-number {
    cursor: pointer;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    border: 3px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin: auto;
    position: relative;
    @media (max-width: variables.$breakpoint-lg) {
      width: 36px;
      height: 36px;
    }
  }
  &-replaced {
    position: absolute;
    bottom: -3px;
    right: -3px;
    background-color: white;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: variables.$breakpoint-lg) {
      svg {
        width: 10px;
        height: 10px;
      }
    }
  }
  &-name {
    text-transform: capitalize;
    text-align: center;
    background-color: rgba($color: #000000, $alpha: 0.3);
    padding: 2px 4px;
    border-radius: 5px;
    color: white;
    width: fit-content;
    margin: 2px auto;
    font-size: 12px;
    position: relative;
    @media (max-width: variables.$breakpoint-lg) {
      font-size: 11px;
    }
  }
}
