@use "src/variables";

.page-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  @media (max-width: variables.$breakpoint-lg) {
    margin-bottom: 16px;
  }
  h2 {
    margin: 0;
    font-size: 30px;
    color: variables.$primary-color;
    display: flex;
    align-items: center;
    width: fit-content;
    @media (max-width: variables.$breakpoint-lg) {
      font-size: 24px;
    }
    > div {
      margin-right: 16px;
      svg {
        width: 20px;
        height: 15px;
        transform: rotate(90deg);
      }
    }
  }
  > div {
    display: flex;
    > div:not(:last-child) {
      margin-right: 16px;
    }
  }
}
