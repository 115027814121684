@use "src/variables";

.modal {
  font-size: 16px;
  background-color: rgba($color: #000000, $alpha: 0.6);
  position: fixed; /* Stay in place */
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  &-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    > h3 {
      margin: 0;
    }
    > b {
      cursor: pointer;
      margin-bottom: 8px;
    }
  }
  &-content {
    background-color: rgba($color: #ffffff, $alpha: 1);
    padding: 16px;
    width: 450px;
    flex-wrap: wrap;
    border-radius: 10px;
    > div > div {
      display: flex;
      flex-direction: column;
      > label {
        margin-top: 16px;
        margin-bottom: 8px;
        font-weight: bold;
      }
      > input {
        padding: 6px;
        border-radius: 10px;
      }

      > div {
        display: flex;
        align-items: center;
        > span {
          cursor: pointer;
          opacity: 0.6;
          &:first-child {
            margin-right: 16px;
          }
        }
      }
    }
  }
}
