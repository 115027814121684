@use "src/variables";

.form__item {
  margin-bottom: 24px;
  &-label {
    margin-bottom: 8px;
    color: black;
    display: block;
    font-weight: bold;
  }
  &-holder {
    height: 50px;
    @media (max-width: variables.$breakpoint-lg) {
      height: 40px;
    }
    > input {
      border: 1px solid variables.$secondary-color;
      width: calc(100% - 32px);
      height: 100%;
      padding: 0 16px;
      &::placeholder {
        color: variables.$secondary-color;
      }
    }
  }
}
.error-message {
  margin-top: 6px;
  font-size: 14px;
  color: red;
}
