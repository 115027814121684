@use "/src/variables";

.loader {
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    width: 40px;
    height: 40px;
    margin: 8px;
    border-radius: 50%;
    background-color: variables.$primary-color;
    animation: loader 5s cubic-bezier(0, 0.2, 0.8, 1) infinite;

    &:nth-child(1) {
      left: 8px;
      animation-delay: -0.24s;
    }
    &:nth-child(2) {
      left: 28px;
      animation-delay: -0.12s;
    }
    &:nth-child(3) {
      left: 48px;
      animation-delay: 0;
    }
  }
}
@keyframes loader {
  0%,
  100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}
