@use "src/variables";

.activity-widget {
  > ul {
    padding: 0;
    margin: 4px 0 0 0;
    > li {
      color: rgba($color: variables.$primary-color, $alpha: 0.5);
      list-style: none;
      font-weight: bold;
      &:first-child {
        color: rgba($color: variables.$primary-color, $alpha: 0.7);
        margin-right: 8px;
      }
    }
  }
  &-POSITIVE {
    color: variables.$POSITIVE-color;
  }
  &-NEGATIVE {
    color: variables.$NEGATIVE-color;
  }
}
