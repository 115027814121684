@use "/src/variables";

.login-form {
  > h2 {
    margin-top: 4px;
    margin-bottom: 32px;
    font-size: 50px;
    @media (max-width: variables.$breakpoint-lg) {
      font-size: 40px;
      margin-bottom: 24px;
    }
  }
  &-inputs {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    > div:not(:first-child) {
      margin-bottom: 4px;
    }
    > small a {
      color: black;
    }
  }
  &-save {
    > button {
      cursor: pointer;
      margin-top: 16px;
      background-color: variables.$primary-color;
      border: 2px solid transparent;
      height: 50px;
      width: 100%;
      color: white;
      font-weight: 600;
      @media (max-width: variables.$breakpoint-lg) {
        height: 40px;
      }
      &:hover {
        background-color: white;
        border: 2px solid variables.$primary-color;
        color: variables.$primary-color;
      }
    }
  }
}
