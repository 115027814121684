@use "src/variables";

.sessions {
  &-header {
    margin-top: 16px;
    display: flex;
    > span {
      display: flex;
      align-items: center;
      cursor: pointer;
      text-transform: capitalize;
      svg {
        margin-left: 4px;
      }
    }
  }
  &-content {
    > div {
      display: flex;
      align-items: center;
      margin-top: 16px;
      cursor: pointer;
      > span > div {
        margin: 0 auto;
      }
    }
  }
  &-header,
  &-content > div {
    > span {
      display: flex;
      flex: 1;
      &:not(:nth-child(2)) {
        justify-content: center;
      }
      &:not(:last-child) {
        margin-right: 12px;
      }
      &:first-child {
        min-width: 75px;
        @media (max-width: variables.$breakpoint-lg) {
          min-width: 65px;
        }
      }
    }
  }
}
