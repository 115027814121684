@use "src/variables";

.audio-control {
  width: 100%;
  padding: 8px 12px;
  background-color: variables.$primary-color;
  color: white;
  border-radius: 10px;
  box-sizing: border-box;
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > span:last-child {
      font-weight: bold;
      cursor: pointer;
      background-color: rgba($color: #ffffff, $alpha: 0.2);
      border-radius: 5px;
      padding: 0 8px;
      &:hover {
        background-color: rgba($color: #ffffff, $alpha: 0.4);
      }
    }
  }
  &-content {
    audio {
      width: 100%;
      border-radius: 5px;
    }
  }
}
