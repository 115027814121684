@use "src/variables";

.page-widgets {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 32px;

  @media (max-width: variables.$breakpoint-lg) {
    margin-bottom: 24px;
  }
  > div {
    width: 24%;
    @media (max-width: variables.$breakpoint-lg) {
      margin-bottom: 24px;
      width: 48%;
    }
    &:last-child {
      > div h6 span div {
        left: -130px;
      }
    }
    > div {
      min-height: 100%;
    }
  }
  &-details {
    &-column {
      width: 30%;
      display: flex;
      flex-direction: column;
      > div {
        width: 100%;
        min-height: unset !important;
        &:first-child {
          margin-bottom: 24px;
        }
      }
    }
    > div {
      width: 50%;
    }
  }
}
