@use "src/variables";

.tag-item {
  width: 100%;
  padding: 8px;
  position: relative;

  &:not(:first-child) {
    margin-top: 16px;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    small {
      color: variables.$primary-color;
      &::after {
        content: "|";
        font-size: 16px;
        padding: 0 8px;
        color: rgba($color: variables.$primary-color, $alpha: 0.3);
      }
    }
    > textarea {
      flex: 1;
      padding: 6px 2px;
      border-radius: 5px;
      border-width: 2px;
      margin: 0 8px;
    }
  }
  &-title {
    display: flex;
    align-items: center;
    > span {
      display: flex;
      align-items: center;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  &-color {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid variables.$primary-color;
    margin-right: 6px;
    cursor: pointer;
  }

  &-icons {
    display: flex;
    align-items: center;
    svg {
      width: 18px;
      height: 18px;
      padding: 4px 8px;
      border-radius: 15px;
      cursor: pointer;
      &:hover {
        background-color: rgba($color: #000000, $alpha: 0.1);
      }
    }
  }
  &-buttons {
    > div:first-child {
      margin-bottom: 8px;
    }
  }
  &-delete {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: variables.$primary-color;
    border-radius: 10px;
    > div {
      width: 100%;
      padding: 4px 8px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      > div:first-child {
        margin-right: 12px;
        background-color: red;
        &:hover {
          background-color: white;
        }
      }
    }
  }
}
