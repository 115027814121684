@use "src/variables";

.team-item {
  display: flex;
  height: 75px;
  border-radius: 10px;
  border: 1px solid variables.$tertiary-color;
  box-shadow: 3px 5px rgba($color: variables.$primary-color, $alpha: 0.2);
  overflow: hidden;
  cursor: pointer;
  @media (max-width: variables.$breakpoint-lg) {
    height: 60px;
  }
  &-left {
    width: calc(100% - 35px);
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: variables.$primary-color;
    @media (max-width: variables.$breakpoint-lg) {
      width: calc(100% - 28px);
    }
    > span {
      font-size: 24px;
      @media (max-width: variables.$breakpoint-lg) {
        font-size: 18px;
      }
    }
  }
  &-right {
    width: 35px;
    position: relative;
    background-color: variables.$admin-color;
    font-size: 13px;
    @media (max-width: variables.$breakpoint-lg) {
      width: 28px;
      font-size: 11px;
    }
    > span {
      color: white;
      white-space: nowrap;
      position: absolute;
      right: -10px;
      bottom: 32px;
      display: flex;
      justify-content: center;
      transform: rotate(90deg);
      @media (max-width: variables.$breakpoint-lg) {
        bottom: 26px;
      }
    }
  }
}
