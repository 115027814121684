@use "src/variables";

.usage-widget {
  &-content {
    display: flex;
    justify-content: space-between;
    > div:first-child {
      height: 80px;
      width: 100%;
      @media (max-width: variables.$breakpoint-lg) {
        height: 70px;
      }
    }
    &-right {
      margin-left: 8px;
      text-align: right;
      > h3 {
        font-size: 24px;
        @media (max-width: variables.$breakpoint-lg) {
          font-size: 18px;
        }
      }
      > div {
        margin-top: 8px;
        text-align: left;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        > div {
          font-size: 13px;
          margin-left: 8px;
        }
      }
    }
  }
  &-graph {
    width: 45% !important;
    @media (max-width: variables.$breakpoint-lg) {
      width: calc(55% - 8px) !important;
    }
  }
}
