@use "src/variables";

.view-team {
  &-buttons {
    display: flex;
    > div:last-child {
      border-radius: 5px;
      margin-left: 12px;
      svg {
        margin-top: 2px;
        width: 14px;
        height: 14px;
        path {
          fill: transparent;
        }
      }
    }
  }
  &-form {
    > div {
      margin-top: 28px;
      display: flex;
      flex-direction: column;
      > small {
        color: variables.$secondary-color;
      }
      > div {
        margin-top: 12px;
      }
    }
  }
}
