@use "src/variables";

.audio-player {
  width: 100%;
  height: 100%;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &-active {
    background-color: #5085f0;
    color: white;
  }
  &-canvas {
    position: absolute;
    z-index: 100;
    width: 48px;
    height: 48px;
    @media (max-width: variables.$breakpoint-lg) {
      left: -3px;
      bottom: -3px;
      width: 42px;
      height: 42px;
    }
  }
  &-coach {
    padding: 2px 4px;
    display: flex;
    align-items: center;
    font-size: 13px;
    border: 2px solid white;
    border-radius: 5px;
    @media (max-width: variables.$breakpoint-lg) {
      font-size: 11px;
      padding: 2px;
    }
    > div {
      margin-left: 2px;
      width: 15px;
      height: 15px;

      @media (max-width: variables.$breakpoint-lg) {
        width: 13px;
        height: 13px;
      }
    }
  }
}
