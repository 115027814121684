@font-face {
  font-family: "Futura";
  src: local("Futura"),
    url("./../public/fonts/Futura-medium.ttf") format("truetype");
  font-weight: 500;
}
body {
  font-weight: 500;
  font-family: "Futura";
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
