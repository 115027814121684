@use "src/variables";

.audio-overview {
  width: 100%;
  border-radius: 10px;
  box-shadow: 3px 5px rgba($color: variables.$primary-color, $alpha: 0.2);

  overflow: hidden;
  color: white;
  background-color: white;
  &-top {
    background-color: variables.$primary-color;
    display: flex;
    justify-content: center;
    > u {
      margin: 0 4px;
    }
  }
  &-header {
    font-weight: bold;
    padding: 4px;
    background-color: variables.$primary-color;
    text-align: center;

    > div:not(:first-child) {
      flex: 1;
    }
  }
  &-content {
    overflow-y: auto;
  }
  &-row {
    display: flex;
    align-items: center;
    height: 30px;
    border: 1px solid variables.$tertiary-color;
    > div {
      box-sizing: border-box;
      padding: 0 4px;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
  &-player {
    width: 120px;
    background-color: variables.$primary-color;
  }
  &-audios {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    width: calc(100% - 120px);
    &-item {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      > div {
        border-radius: 5px;
        box-sizing: border-box;
        background-color: variables.$secondary-color;
        height: 75%;
        border: 2px solid variables.$primary-color;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          cursor: pointer;
          background-color: variables.$primary-color;
        }
        > div {
          max-width: 60%;
          width: 40%;
          span {
            transform: scale(0.8);
            background-color: white;
          }
        }
      }
    }
  }
}
