@use "src/variables";

.squad-table {
  overflow-x: auto;

  table {
    min-width: 950px;
    width: 100%;
    border-collapse: collapse;
    thead {
      border-bottom: 1px solid variables.$tertiary-color;
      height: 60px;
      text-align: center;
      text-transform: capitalize;
      tr:first-child th {
        padding-top: 24px;
        font-size: 13px;
        color: variables.$secondary-color;
        @media (max-width: variables.$breakpoint-lg) {
          padding-top: 12px;
        }
        &:nth-child(2n) {
          background-color: rgba(
            $color: variables.$tertiary-color,
            $alpha: 0.1
          );
        }
        &:nth-child(3) {
          background-color: white;
        }
      }
    }
    tbody tr {
      height: 60px;
      border-bottom: 1px solid #f3f3f3;
      cursor: pointer;
      &:hover {
        background-color: rgba($color: #dadada, $alpha: 0.2);
      }
    }

    th,
    td {
      padding: 8px;
      &:last-child > div {
        display: flex;
        align-items: center;
      }
      &:nth-child(2n + 1):not(:first-child),
      &:nth-child(4) {
        background-color: rgba($color: variables.$tertiary-color, $alpha: 0.1);
      }
    }
    thead tr {
      th > span {
        width: 100%;
        display: flex;
        cursor: pointer;
        font-weight: normal;
        svg {
          width: 12px;
          height: 10px;
          margin-left: 8px;
        }
      }
    }
  }
  &-progress {
    background-color: variables.$NEGATIVE-color;
    min-width: 80px;
    width: 65%;
    height: 10px;
    border-radius: 5px;
    display: flex;
    overflow: hidden;
    margin: auto;
    > div {
      background-color: variables.$POSITIVE-color;
    }
  }
}
