@use "src/variables";

.wave-form {
  margin-top: 12px;
  &-time {
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    > span {
      width: 100px;
      &:last-child {
        text-align: right;
      }
    }
  }
  &-buttons {
    width: calc(100% - 232px);
    display: flex;
    align-items: center;
    margin: 0 16px;
    justify-content: space-between;
    > div {
      display: flex;
      align-items: center;
      gap: 12px;
      > span {
        cursor: pointer;
        padding: 0 6px;
        border-radius: 5px;
        background-color: rgba($color: #ffffff, $alpha: 0.2);
        color: white;
        > div > div {
          display: flex;
          align-items: center;
        }
        svg {
          width: 21px;
          height: 21px;
          stroke: white;
          stroke-width: 4;
        }
        &:hover {
          background-color: rgba($color: #ffffff, $alpha: 0.4);
        }
      }
    }
  }
  &-container {
    padding: 2px 8px;
    border: 1px solid white;
    background-color: white;
    height: 30px;
    border-radius: 5px;
  }
}
