@use "/src/variables";

.login-layout {
  &-header {
    width: 100%;
    height: 80px;
    background-color: black;
    display: flex;
    align-items: center;
    > div {
      height: 50px;
      width: 150px;
      margin-left: 24px;
      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  &-content {
    display: flex;
    height: calc(100vh - 80px);
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    &-left {
      background-color: black;
      background-image: url("../../../../public/img/login.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position-y: bottom;
      width: 50%;
      @media (max-width: variables.$breakpoint-xl) {
        width: 40%;
      }
      > h1 {
        margin: 0;
        color: white;
        font-size: 100px;
        @media (max-width: variables.$breakpoint-lg) {
          font-size: 72px;
        }
      }
    }
    &-right {
      width: 50%;
      @media (max-width: variables.$breakpoint-xl) {
        width: 60%;
      }
      button {
        cursor: pointer;
        margin-top: 16px;
        background-color: variables.$primary-color;
        border: 2px solid transparent;
        height: 50px;
        width: 100%;
        color: white;
        font-weight: 600;
        @media (max-width: variables.$breakpoint-lg) {
          height: 40px;
        }
        &:hover {
          background-color: white;
          border: 2px solid variables.$primary-color;
          color: variables.$primary-color;
        }
      }
    }
  }
}
