@use "src/variables";

.field-overview {
  width: 100%;
  &-top {
    background-image: url("../../../../public/img/field.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 55%;
    min-height: 400px;
    border-radius: 10px 10px 0 0;
    position: relative;
    @media (max-width: variables.$breakpoint-lg) {
      min-height: 320px;
    }
    &-rotate {
      background-image: url("../../../../public/img/field-flipped.png");
      .field-overview-players {
        transform: rotate(180deg);
        > div {
          transform: rotate(180deg);
          margin-bottom: 24px;
          @media (max-width: variables.$breakpoint-lg) {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
  &-players {
    padding: 16px 4px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    > div:not(:last-child) {
      margin-bottom: 20px;
      @media (max-width: variables.$breakpoint-lg) {
        margin-bottom: 12px;
      }
    }
  }
  &-formation {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 2;
    display: flex;
    align-items: center;
    > span {
      margin-right: 8px;
      padding: 1px;
      background-color: black;
      cursor: pointer;
      border-radius: 50%;
      border: 1px solid black;
      display: flex;
      align-items: center;
      width: 14px;
      height: 14px;
      svg {
        margin: 0 0 1px -1px;
        path {
          fill: white;
          transform: scale(0.7);
        }
      }
    }
    ul {
      font-size: 13px;
    }

    > div > div {
      &:first-child > div {
        padding: 2px 6px;
        font-size: 13px;
        background-color: rgba($color: #000000, $alpha: 0.3);
      }
      &:last-child {
        top: 30px !important;
      }
    }
  }
  &-gradient {
    > div {
      &:first-child {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        > span {
          color: variables.$tertiary-color;
          font-size: 13px;
        }
      }
      &:last-child {
        display: flex;
        width: 100%;
        height: 15px;
        > span {
          width: 20%;
        }
      }
    }
  }
  &-buttons {
    > div {
      &:first-child {
        border-bottom: 1px solid variables.$tertiary-color;
        //  border-top: 1px solid variables.$tertiary-color;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 13px;
        > input {
          box-sizing: border-box;
          width: 30%;
          border: 0;
          padding: 4px 8px;
        }
      }
      &:last-child {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        > div {
          width: 32%;
          margin-bottom: 8px;
          &:nth-last-child(-n + 2) {
            width: 49%;
          }
        }
      }
    }
  }
  &-bottom {
    > div:first-child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: rgba($color: #dadada, $alpha: 0.4);
      padding: 8px 2px;
      border: 1px solid variables.$tertiary-color;
      margin-bottom: 8px;
      svg {
        cursor: pointer;
      }
      .field-overview-swaps {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        > div {
          width: 25%;
          margin-bottom: 8px;
          > div {
            border-color: variables.$primary-color;
            width: 42px;
            height: 42px;
            @media (max-width: variables.$breakpoint-lg) {
              width: 36px;
              height: 36px;
            }
          }
          > span {
            background-color: rgba(
              $color: variables.$primary-color,
              $alpha: 0.6
            );
          }
        }
      }
    }
  }
  &-replacement {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
