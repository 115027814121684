@use "src/variables";

.interactions {
  &-content {
    display: flex;
    align-items: center;
    > span {
      transform: rotate(90deg);
      margin: 0 6px;
      cursor: pointer;
      &:last-child {
        transform: rotate(-90deg);
      }
    }
    > div {
      width: calc(100%) !important;
    }
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    @media (max-width: variables.$breakpoint-lg) {
      margin-bottom: 16px;
    }
    > div {
      display: flex;
      align-items: center;
      > *:not(:last-child) {
        margin-right: 16px;
      }
      > span svg {
        cursor: pointer;
        width: 23px;
        height: 23px;
        @media (max-width: variables.$breakpoint-lg) {
          width: 18px;
          height: 18px;
        }
      }
    }
    > ul {
      overflow-y: auto;
      max-height: 300px;
      > li {
        display: flex;
        align-items: center;
        padding: 4px 8px;
        > input {
          margin-right: 4px;
        }
      }
    }
  }
  &-buttons {
    display: flex;
    margin-bottom: 24px;
    @media (max-width: variables.$breakpoint-xl) {
      margin-bottom: 0;
    }
    > div {
      border-radius: 5px;
      width: fit-content;
      &:not(:last-child) {
        margin-right: 16px;
        @media (max-width: variables.$breakpoint-lg) {
          margin-right: 12px;
        }
      }
    }
  }
  &-switch {
    display: flex;
    justify-content: center;
    margin-top: 12px;
    > span {
      cursor: pointer;
      display: block;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      background-color: #2c2f51;
      &:first-child {
        margin-right: 12px;
      }
    }
    &-active {
      opacity: 0.4;
    }
  }
  &-graph {
    height: 320px;
    width: 100%;
    @media (max-width: variables.$breakpoint-xl) {
      height: 280px;
    }
    @media (max-width: variables.$breakpoint-lg) {
      height: 240px;
    }
  }
  &-big {
    min-width: 1200px;
    .interactions-header {
      margin-bottom: 24px;
      h6 {
        font-size: 28px;
      }
      div {
        font-size: 18px;
      }
    }
    .interactions-buttons div {
      font-size: 20px;
    }
    .interactions-graph {
      height: 500px;
      width: 100%;
    }
    .interactions-switch > span {
      width: 20px;
      height: 20px;
    }
  }
}
