@use "src/variables";

.classifications-widget {
  &-content {
    margin-bottom: -4px;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: variables.$breakpoint-lg) {
      margin-bottom: -8px;
    }
    > div {
      padding: 2px 0;
      border-radius: 5px;
      width: 49%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      margin-bottom: 4px;
      @media (max-width: variables.$breakpoint-lg) {
        margin-bottom: 8px;
      }
      > span {
        font-size: 20px;
        font-weight: bold;
      }
      > small {
        color: variables.$secondary-color;
        text-align: center;
      }
    }
  }
  &-big {
    margin-bottom: -20px;
    @media (max-width: variables.$breakpoint-xl) {
      margin-bottom: -12px;
    }
    > div {
      margin-bottom: 20px;
      @media (max-width: variables.$breakpoint-xl) {
        margin-bottom: 12px;
      }
      span {
        font-size: 32px;
        @media (max-width: variables.$breakpoint-xl) {
          font-size: 28px;
        }
      }
      small {
        font-size: 18px;
        @media (max-width: variables.$breakpoint-xl) {
          font-size: 16px;
        }
      }
    }
  }
}
